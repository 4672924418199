.button{
    background-color: $color-fith;
    padding: 15px 25px;
    border-radius: 50px;
    border: none;
    color: $color-third;
    font-weight: 500;
    cursor: pointer;

    &__color{
        background-color: $color-third;
        color:$color-second;
    }
}
