$color-first: #9d97cf;
// $color-second: #eddddd;
$color-second: #eddeea;
$color-second-dark: #e2cade;
$color-third: #3E618B;
$color-fourth: #8863AB;
// $color-fourth:#442063;
$color-fith: #8AC9B8;

$shadow: -0.6rem 0.6rem 0 rgba(29, 30, 28, 0.26);



$bg-gradient: linear-gradient(90deg, rgba(68,32,99,1) 0%, rgba(52,37,103,1) 28%, rgba(29,50,99,1) 44%, rgba(31,45,81,1) 100%);
// $bg-gradient: linear-gradient(90deg, rgba(157,151,207,1) 0%, rgba(68,32,99,1) 18%, rgba(52,37,103,1) 41%, rgba(29,50,99,1) 54%, rgba(31,45,81,1) 100%);

// linear-gradient(90deg, rgba(157,151,207,1) 0%, rgba(72,64,140,1) 28%, rgba(29,50,99,1) 44%, rgba(31,45,81,1) 100%);

$bp-m: 767.98px;
$bp-t: 1024px;
$bp-ld: 1600px;
