.presentation{
    @include container;

    &__content{
        @include content;

        @media (max-width: $bp-t) {
            align-items: center;
            gap:20px;
        }

        &__photo{
            width:300px;
            height: 300px;
            object-fit:cover;
            border-radius: 50%;

            @media (max-width: $bp-t) {
                width: 200px;
                height:200px;
            }
        }

        &__txt{
            width: 60%;
            text-align:center;
            line-height: 1.75;
            display: flex;
            flex-direction: column;
            gap: 30px;

            @media (max-width: $bp-t) {
                width:auto;
            }
        }
    }

    &__details{
        font-size: 18px;

        @media (max-width: $bp-t) {
            text-align: center;
        }

        &--color{
            color: $color-first;
            margin: 0 150px;
            text-align: center;

            @media (max-width: $bp-t) {
                margin:auto;
            }
        }
    }


}

.realisations{
    @include container;

    &__content{
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: $bp-t) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__button{
        background-color: #2c1e4a;
        color: white;
        width: 100%;
        height: 40px;
        cursor: pointer;
        border: none;
    }
}

.competences{
    @include container;


    &__content{
        @include content;

        &--hardSkills{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap:25px;

            &--grid{
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-gap: 15px;

                @media (max-width: $bp-m) {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }

        &--softSkills{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap:25px;

            &--grid{
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 50px;
                padding-top: 20px;

                @media (max-width: $bp-m) {
                    padding:0;
                    display:block;
                    text-align: center;
                }

                li{
                    font-size: 16px;
                }
            }
        }

    }
}

.offres{
    @include container-large;
    background-color: $color-first;

    &__content{
        display: flex;
        gap:30px;

        @media (max-width: $bp-t) {
            flex-direction: column;
        }
    }
}

.contact{
    @include container;

    &__content{
        @include content;

        &--link{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap:30px;

            
        }
    }
    
}