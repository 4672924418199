
.header{
    max-width: 100vw;
    height: 40px;
    padding: 10px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    z-index:6;
    position:sticky;
    top:0;
    background: radial-gradient(circle, rgb(237, 222, 234) 0%, rgb(226, 202, 222) 100%);


    @media (max-width: $bp-m) {
        height: auto;
        flex-direction: column;
    }


    &__logo{
        height: 130%;

        @media (max-width: $bp-m) {
            height: 20px;
        }

        &--img{
            height: 100%;
        }
    }

    &__nav{
        display: flex;
        justify-content: flex-end;
        margin-right: 20px;


        @media (max-width: $bp-m) {
            flex-wrap: wrap;
            justify-content: center;
            gap:5px;
            margin-top: 15px;
        }

        &--link{
            font-family: "Poppins", sans-serif;
            text-decoration: none;
            font-size: 20px;
            color: $color-third;
            list-style: none;
            padding: 5px 15px;


            @media (max-width: $bp-m) {
                padding:5px 15px;
                font-size:14px;
            }

            &:hover{
                color: white;
                background-color: #9d97cf;
            }

            &:active{
                color: white;
                background-color: #9d97cf;
            }
        }
    }
}

/* Style pour le menu déroulant */
.dropdown-menu {
    display: none; /* Par défaut, le menu déroulant est caché */
    position: absolute;
    background-color: $color-second-dark;
    list-style: none;
    margin: 5px 0 0 50px;
    padding:5px 0;

    flex-direction: column;
    gap:10px;

    @media (max-width: $bp-m) {
        margin:0;
        padding:0;
    }
  }
  
  .dropdown-item {
    width: 100%;
    padding: auto;
  }
  
  /* Afficher le menu déroulant au survol */
  .dropdown:hover .dropdown-menu {
    display: flex;
  }