@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');



.formuladetailscard{
    width: 400px;
    height: 500px;
    border-radius: 75px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:40px;
    background: radial-gradient(circle, rgba(157,151,207,1) 30%, 	rgb(128, 115, 191) 100%);

    @media (max-width: $bp-t){
        width: 350px;
        height: auto;
        gap:10px;
        padding-bottom: 20px;
    }

    @media (max-width: $bp-m) {
        width: 95%;
        height: auto;
        gap: 20px;
    }


    &__title{
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        color: $color-second;
        margin: 50px 10px 0 10px;
    }

    &__txt{
            color: $color-third;
            text-align: justify;
            margin: 0 50px;
            font-size: 14px;

            @media (max-width: $bp-t) {
                margin:0 10px 20px 10px;
            }
        }
    
    &--conclusion{
        background: none;
        color: $color-third;
        justify-content: center;

        .formuladetailscard__title{
            color: $color-fourth;
            margin:0;
        }

        .formuladetailscard__txt{
            color: $color-third;
            margin:0;
        }
    }

    &--green{
        background: radial-gradient(circle, rgb(138, 201, 184) 30%, rgb(72, 157, 135) 100%);
        
    }
}