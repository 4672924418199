@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Mulish&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gruppo&family=Mulish&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gruppo&family=Mulish&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rajdhani&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
* {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  color: #3E618B;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #eddeea;
  box-sizing: content-box;
  max-width: 100vw;
}

li {
  list-style: none;
}

h1 {
  text-align: center;
  font-size: 50px;
  font-family: "Gruppo", sans-serif;
  color: #9d97cf;
  margin: 10px 0 20px 0;
}
@media (max-width: 1024px) {
  h1 {
    font-size: 40px;
  }
}

h2 {
  text-align: center;
  font-size: 40px;
  font-family: "Gruppo", sans-serif;
  font-weight: 500;
  color: #8863AB;
}
@media (max-width: 767.98px) {
  h2 {
    font-size: 30px;
  }
}

h3 {
  font-family: "Gruppo", sans-serif;
  font-size: 40px;
  font-weight: 500;
}

h4 {
  font-family: "Gruppo", sans-serif;
  font-size: 30px;
  font-weight: 500;
}

.link {
  font-size: 20px;
}
.link:hover {
  color: #8AC9B8;
}

.header {
  max-width: 100vw;
  height: 40px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 6;
  position: sticky;
  top: 0;
  background: radial-gradient(circle, rgb(237, 222, 234) 0%, rgb(226, 202, 222) 100%);
}
@media (max-width: 767.98px) {
  .header {
    height: auto;
    flex-direction: column;
  }
}
.header__logo {
  height: 130%;
}
@media (max-width: 767.98px) {
  .header__logo {
    height: 20px;
  }
}
.header__logo--img {
  height: 100%;
}
.header__nav {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}
@media (max-width: 767.98px) {
  .header__nav {
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    margin-top: 15px;
  }
}
.header__nav--link {
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  font-size: 20px;
  color: #3E618B;
  list-style: none;
  padding: 5px 15px;
}
@media (max-width: 767.98px) {
  .header__nav--link {
    padding: 5px 15px;
    font-size: 14px;
  }
}
.header__nav--link:hover {
  color: white;
  background-color: #9d97cf;
}
.header__nav--link:active {
  color: white;
  background-color: #9d97cf;
}

/* Style pour le menu déroulant */
.dropdown-menu {
  display: none; /* Par défaut, le menu déroulant est caché */
  position: absolute;
  background-color: #e2cade;
  list-style: none;
  margin: 5px 0 0 50px;
  padding: 5px 0;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 767.98px) {
  .dropdown-menu {
    margin: 0;
    padding: 0;
  }
}

.dropdown-item {
  width: 100%;
  padding: auto;
}

/* Afficher le menu déroulant au survol */
.dropdown:hover .dropdown-menu {
  display: flex;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3E618B;
  padding: 50px 0;
}
.footer__txt {
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
}
.footer__txt--button {
  cursor: pointer;
}
.footer__txt--button:hover {
  color: pink;
}

.card {
  padding: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .card {
    flex-direction: column-reverse;
  }
}
.card__content {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media (max-width: 1024px) {
  .card__content {
    width: auto;
  }
}
.card__content--txt {
  font-family: "Gruppo", sans-serif;
  font-size: 18px;
}
.card__content--buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  perspective: 1000px;
}
.card__content--buttons--details {
  cursor: pointer;
  border: none;
  background-color: #1f2d51;
  color: white;
  font-family: "Gruppo", sans-serif;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  padding: 0 40px;
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}
.card__content--buttons--details:hover {
  transform: translateZ(50px);
  background-color: #9d97cf;
}
.card__img {
  width: 50%;
  height: auto;
}
@media (max-width: 1024px) {
  .card__img {
    width: 100%;
  }
}

.skill {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  perspective: 1000px;
  z-index: 1;
}
@media (max-width: 1024px) {
  .skill {
    height: 50px;
    width: 50px;
  }
}
.skillInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.skillInner__logo {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}
.skillInner__logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.skillInner__name {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: black;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}
.skillInner__name p {
  font-size: 12px;
}
.skill:hover .skillInner {
  transform: rotateY(180deg);
}

.contactForm {
  border: 1px solid black;
  width: 500px;
  background-color: #e2cade;
  margin: 0;
  box-shadow: -0.6rem 0.6rem 0 rgba(29, 30, 28, 0.26);
}
@media (max-width: 767.98px) {
  .contactForm {
    width: auto;
  }
}
.contactForm__title {
  text-align: center;
  padding: 8px;
  border-bottom: 1px solid black;
}
.contactForm__content {
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.contactForm__content--input {
  border: 2px solid rgba(0, 0, 0, 0.6);
  background-image: none;
  background-color: #eddeea;
  box-shadow: none;
  padding: 5px;
}
.contactForm__content .button {
  margin-top: 10px;
  align-self: flex-end;
  cursor: pointer;
  font-weight: 500;
}
.contactForm__content textarea {
  min-height: 50px;
  resize: vertical;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.modal__content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 40%;
  height: 70%;
}
@media (max-width: 1024px) {
  .modal__content {
    width: 70%;
  }
}
.modal__content--close {
  position: absolute;
  top: 5px;
  right: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 20px;
  cursor: pointer;
}
.modal__content--title {
  font-weight: 700;
  font-size: 40px;
}
@media (max-width: 767.98px) {
  .modal__content--title {
    font-size: 30px;
  }
}
.modal__content--txt {
  max-height: 90%;
  overflow-y: auto;
}
.modal__content--paragraph {
  margin-top: 20px;
}

.banner {
  background: linear-gradient(90deg, rgb(68, 32, 99) 0%, rgb(52, 37, 103) 28%, rgb(29, 50, 99) 44%, rgb(31, 45, 81) 100%);
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  top: 0;
  height: 100vh;
}
@media (max-width: 1024px) {
  .banner {
    flex-direction: column;
    justify-content: center;
  }
}
.banner__logo {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767.98px) {
  .banner__logo {
    width: 100%;
  }
}
.banner__logo--img {
  width: 55%;
}
.banner__content {
  width: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 40px;
  gap: 40px;
  padding-right: 100px;
}
@media (max-width: 1024px) {
  .banner__content {
    margin: 0 10px;
    padding: 120px 10px;
  }
}
@media (max-width: 1024px) {
  .banner__content {
    width: auto;
    padding: 0 0 80px 0;
    margin: 10px;
  }
}
.banner__content--title {
  font-size: 50px;
  font-weight: 400;
  font-family: "Times New Roman", Times, serif;
}
@media (max-width: 1024px) {
  .banner__content--title {
    font-size: 25px;
    text-align: center;
  }
}

.offercard {
  width: 300px;
  border-radius: 75px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  gap: 15px;
  background: radial-gradient(circle, rgb(157, 151, 207) 30%, rgb(128, 115, 191) 100%);
  box-shadow: 0 0 15px 5px rgb(128, 115, 191);
}
@media (min-width: 1600px) {
  .offercard {
    width: 400px;
  }
}
@media (max-width: 1024px) {
  .offercard {
    width: 100%;
    max-width: 400px;
    min-width: 300px;
  }
}
.offercard__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  height: 100px;
}
.offercard__header--title {
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  color: #eddeea;
  z-index: 2;
}
.offercard__header--img {
  position: absolute;
  width: 40%;
}
.offercard__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
}
.offercard__content--txt1 {
  color: #eddeea;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: #3E618B;
}
.offercard__content--txt2 {
  color: #eddeea;
  text-align: justify;
  font-size: 13px;
}
.offercard__content--txt3 {
  color: #eddeea;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}

.formulacard {
  width: 350px;
  height: 750px;
  border-radius: 75px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 15px;
  background: radial-gradient(circle, rgb(157, 151, 207) 30%, rgb(128, 115, 191) 100%);
}
.formulacard__special {
  width: 400px;
  height: 800px;
  background: radial-gradient(circle, rgb(138, 201, 184) 30%, rgb(72, 157, 135) 100%);
  box-shadow: 1.2rem 1.2rem 0.3rem rgba(29, 30, 28, 0.26);
}
.formulacard__special h3 {
  font-size: 50px;
}
.formulacard__special h4 {
  font-size: 38px;
}
.formulacard__special .formulacard__header--price {
  font-size: 45px;
}
.formulacard__special .formulacard__content--txt1 {
  font-size: 20px;
}
.formulacard__special .formulacard__content--txt2 {
  font-size: 16px;
}
@media (max-width: 1024px) {
  .formulacard {
    width: 100%;
    max-width: 400px;
    min-width: 300px;
  }
}
@media (max-width: 767.98px) {
  .formulacard {
    height: auto;
  }
}
.formulacard__title {
  text-align: center;
  font-size: 35px;
  font-weight: 400;
  color: #eddeea;
}
.formulacard__stitle {
  text-align: center;
}
.formulacard__price {
  font-family: "Raleway", sans-serif;
  font-size: 35px;
  font-weight: 500;
}
.formulacard__content--txt1 {
  color: #eddeea;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #3E618B;
}
.formulacard__content--txt2 {
  color: #eddeea;
  text-align: justify;
  font-size: 14px;
}

.formuladetailscard {
  width: 400px;
  height: 500px;
  border-radius: 75px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background: radial-gradient(circle, rgb(157, 151, 207) 30%, rgb(128, 115, 191) 100%);
}
@media (max-width: 1024px) {
  .formuladetailscard {
    width: 350px;
    height: auto;
    gap: 10px;
    padding-bottom: 20px;
  }
}
@media (max-width: 767.98px) {
  .formuladetailscard {
    width: 95%;
    height: auto;
    gap: 20px;
  }
}
.formuladetailscard__title {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  color: #eddeea;
  margin: 50px 10px 0 10px;
}
.formuladetailscard__txt {
  color: #3E618B;
  text-align: justify;
  margin: 0 50px;
  font-size: 14px;
}
@media (max-width: 1024px) {
  .formuladetailscard__txt {
    margin: 0 10px 20px 10px;
  }
}
.formuladetailscard--conclusion {
  background: none;
  color: #3E618B;
  justify-content: center;
}
.formuladetailscard--conclusion .formuladetailscard__title {
  color: #8863AB;
  margin: 0;
}
.formuladetailscard--conclusion .formuladetailscard__txt {
  color: #3E618B;
  margin: 0;
}
.formuladetailscard--green {
  background: radial-gradient(circle, rgb(138, 201, 184) 30%, rgb(72, 157, 135) 100%);
}

.button {
  background-color: #8AC9B8;
  padding: 15px 25px;
  border-radius: 50px;
  border: none;
  color: #3E618B;
  font-weight: 500;
  cursor: pointer;
}
.button__color {
  background-color: #3E618B;
  color: #eddeea;
}

.presentation {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 40px;
  gap: 40px;
}
@media (max-width: 1024px) {
  .presentation {
    margin: 0 10px;
    padding: 120px 10px;
  }
}
.presentation__content {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
@media (max-width: 1024px) {
  .presentation__content {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}
@media (max-width: 1024px) {
  .presentation__content {
    align-items: center;
    gap: 20px;
  }
}
.presentation__content__photo {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
}
@media (max-width: 1024px) {
  .presentation__content__photo {
    width: 200px;
    height: 200px;
  }
}
.presentation__content__txt {
  width: 60%;
  text-align: center;
  line-height: 1.75;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media (max-width: 1024px) {
  .presentation__content__txt {
    width: auto;
  }
}
.presentation__details {
  font-size: 18px;
}
@media (max-width: 1024px) {
  .presentation__details {
    text-align: center;
  }
}
.presentation__details--color {
  color: #9d97cf;
  margin: 0 150px;
  text-align: center;
}
@media (max-width: 1024px) {
  .presentation__details--color {
    margin: auto;
  }
}

.realisations {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 40px;
  gap: 40px;
}
@media (max-width: 1024px) {
  .realisations {
    margin: 0 10px;
    padding: 120px 10px;
  }
}
.realisations__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1024px) {
  .realisations__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.realisations__button {
  background-color: #2c1e4a;
  color: white;
  width: 100%;
  height: 40px;
  cursor: pointer;
  border: none;
}

.competences {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 40px;
  gap: 40px;
}
@media (max-width: 1024px) {
  .competences {
    margin: 0 10px;
    padding: 120px 10px;
  }
}
.competences__content {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
@media (max-width: 1024px) {
  .competences__content {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}
.competences__content--hardSkills {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}
.competences__content--hardSkills--grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
}
@media (max-width: 767.98px) {
  .competences__content--hardSkills--grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
.competences__content--softSkills {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}
.competences__content--softSkills--grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 50px;
  padding-top: 20px;
}
@media (max-width: 767.98px) {
  .competences__content--softSkills--grid {
    padding: 0;
    display: block;
    text-align: center;
  }
}
.competences__content--softSkills--grid li {
  font-size: 16px;
}

.offres {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0;
  padding: 40px 100px;
  gap: 40px;
  background-color: #9d97cf;
}
@media (max-width: 1024px) {
  .offres {
    padding: 120px 10px 0 10px;
  }
}
.offres__content {
  display: flex;
  gap: 30px;
}
@media (max-width: 1024px) {
  .offres__content {
    flex-direction: column;
  }
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 40px;
  gap: 40px;
}
@media (max-width: 1024px) {
  .contact {
    margin: 0 10px;
    padding: 120px 10px;
  }
}
.contact__content {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
@media (max-width: 1024px) {
  .contact__content {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}
.contact__content--link {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.formules {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 40px;
  gap: 40px;
  align-items: normal;
  gap: 20px;
}
@media (max-width: 1024px) {
  .formules {
    margin: 0 10px;
    padding: 120px 10px;
  }
}
.formules__content--center {
  text-align: center;
  font-size: 20px;
  color: #3E618B;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding: 20px 0;
}
.formules__content--center--color {
  color: #8863AB;
}
.formules__content--point {
  margin-left: 40px;
  line-height: 2em;
}
@media (max-width: 767.98px) {
  .formules__content--point {
    margin-left: 5px;
  }
}
.formules__content--choice {
  gap: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .formules__content--choice {
    flex-direction: column;
  }
}

.deroulement {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0;
  padding: 40px 100px;
  gap: 40px;
  padding: 40px;
  background-color: #3E618B;
}
@media (max-width: 1024px) {
  .deroulement {
    padding: 120px 10px 0 10px;
  }
}
@media (min-width: 1600px) {
  .deroulement {
    padding: 20px 250px;
  }
}
@media (max-width: 1024px) {
  .deroulement {
    padding: 20px;
  }
}
.deroulement h3, .deroulement p, .deroulement strong {
  color: #eddeea;
}
.deroulement__intro {
  color: #eddeea;
  text-align: center;
  padding: 0 100px;
}
@media (max-width: 1024px) {
  .deroulement__intro {
    padding: 0;
  }
}
.deroulement__title {
  color: #eddeea;
}
.deroulement__etape {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.deroulement__etape__part1 {
  display: flex;
  align-items: center;
  gap: 100px;
}
@media (max-width: 767.98px) {
  .deroulement__etape__part1 {
    flex-direction: column;
    gap: 10px;
  }
}
.deroulement__etape__part1--img {
  width: 30%;
}
@media (max-width: 767.98px) {
  .deroulement__etape__part1--img {
    width: 80%;
  }
}
.deroulement__etape__part1--content {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.deroulement__etape__part1--content h3 {
  color: #9d97cf;
}
@media (max-width: 1024px) {
  .deroulement__etape__part1--content h3 {
    font-size: 30px;
    text-align: center;
  }
}
.deroulement__etape__part1--content p {
  font-size: 16px;
  text-align: justify;
}
.deroulement__etape__part2 {
  padding: 0 200px 0 100px;
  text-align: justify;
}
@media (max-width: 1024px) {
  .deroulement__etape__part2 {
    padding: 20px 100px 20px 50px;
  }
}
@media (max-width: 767.98px) {
  .deroulement__etape__part2 {
    padding: 20px 10px;
    font-size: 15px;
    text-align: left;
  }
}

.reverse {
  flex-direction: row-reverse;
}
@media (max-width: 767.98px) {
  .reverse {
    flex-direction: column;
  }
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 40px;
  gap: 40px;
}
@media (max-width: 1024px) {
  .details {
    margin: 0 10px;
    padding: 120px 10px;
  }
}
.details__formule {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #3E618B;
}
.details__formule--title {
  font-size: 25px;
  text-align: center;
}
.details__formule--content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
@media (max-width: 1024px) {
  .details__formule--content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767.98px) {
  .details__formule--content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}