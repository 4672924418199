.contactForm{
    border: 1px solid black;
    width:500px;
    // background-color: rgba(229,205,205,1);
    background-color: $color-second-dark;
    margin: 0;
    box-shadow: $shadow;

    @media (max-width: $bp-m) {
        width: auto;
    }

    &__title{
        text-align: center;
        padding: 8px;
        border-bottom: 1px solid black;
    }

    &__content{
        padding: 20px;
        display: flex;
        gap:20px;
        flex-direction: column;
        

        &--input{
            border: 2px solid rgba(0, 0, 0, 0.6);
            background-image:none;
            background-color: $color-second;
            box-shadow: none;
            padding: 5px;
        }

        .button{
            margin-top: 10px;
            align-self: flex-end;
            cursor: pointer;
            font-weight: 500;
        }

        textarea{
            min-height: 50px;
            resize:vertical;
        }

    }
}