@mixin container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    padding:40px 40px;
    gap:40px;

    @media (max-width: $bp-t) {
        margin:0 10px;
        padding:120px 10px;
    }
}

@mixin container-large {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px 0;
    padding:40px 100px;
    gap:40px;

    @media (max-width: $bp-t) {
        padding:120px 10px 0 10px;
    }
}

@mixin content {
    display: flex;
    width: 100%;
    justify-content: space-around;

    @media (max-width: $bp-t) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}