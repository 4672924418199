.skill{
    height: 80px;
    width: 80px;
    border-radius: 50%;
    perspective: 1000px;
    z-index: 1;

    @media (max-width: $bp-t) {
        height: 50px;
        width: 50px;
    }

    &Inner{
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;

        &__logo{
            position: absolute;
            width: 100%;
            height:100%;
            backface-visibility: hidden;

            img{
                width: 100%;
                height:100%;
                border-radius: 50%;
            }
        }
    
        &__name{
            position:absolute;
            width: 100%;
            height:100%;
            border-radius: 50%;
            background-color: black;
            color:white;
            align-items: center;
            justify-content: center;
            display: flex;
            backface-visibility: hidden;
            transform: rotateY(180deg);

            p{
                font-size:12px;
            }
            }
    }

    
    &:hover {
        .skillInner{
            transform: rotateY(180deg);
        }
    }

}