@import url('https://fonts.googleapis.com/css2?family=Gruppo&family=Mulish&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:wght@300&display=swap');


.card{
    padding:5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $bp-t) {
        flex-direction: column-reverse;
    }

    &__content{
        width:40%;
        display: flex;
        flex-direction: column;
        gap:30px;

        @media (max-width: $bp-t) {
            width:auto;
        }

        &--txt{
            font-family: "Gruppo", sans-serif;
            font-size: 18px;
        }

        &--buttons{
            display: flex;
            justify-content: space-between;
            align-items: center;
            perspective: 1000px;

            &--details{
                cursor: pointer;
                border: none;
                background-color: #1f2d51;
                color: white;
                font-family: "Gruppo", sans-serif;
                font-size: 14px;
                font-weight: 600;
                height: 40px;
                padding:0 40px;
                display: inline-block;
                transition: transform 0.3s ease-in-out;

                &:hover{
                    transform: translateZ(50px);
                    background-color: $color-first;
                }
            }

        }
        
    }

    &__img{
        width:50%;
        height: auto;

        @media (max-width: $bp-t) {
            width: 100%;
        }
    }

}
