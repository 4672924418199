.modal { 
    position: fixed; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    background-color: rgba(0, 0, 0, 0.7); 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    z-index: 3;

    &__content{
        position:relative;
        background-color: white;
        padding: 20px;
        border-radius: 8px; 
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
        width: 40%;
        height: 70%;

        @media (max-width: $bp-t) {
            width:70%;
        }

        &--close{
            position: absolute;
            top: 5px;
            right: 15px;
            font-family: "Poppins", sans-serif;
            font-weight: 800;
            font-size: 20px;
            cursor: pointer;
        }

        &--title{
            font-weight: 700;
            font-size: 40px;

            @media (max-width: $bp-m) {
                font-size:30px;
            }
        }

        &--txt{
            max-height: 90%;
            overflow-y: auto;
        }

        &--paragraph{
            margin-top:20px;
        }
    }



}

