@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


.offercard{
    width: 300px;
    border-radius: 75px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding:20px;
    gap:15px;
    background: radial-gradient(circle, rgba(157,151,207,1) 30%, 	rgb(128, 115, 191) 100%);
    box-shadow: 0 0 15px 5px rgb(128, 115, 191);

    @media (min-width: $bp-ld) {
        width:400px;
    }

    @media (max-width: $bp-t) {
        width:100%;
        max-width: 400px;
        min-width: 300px;
    }


    &__header{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:10px;
        height: 100px;

        &--title{
            text-align: center;
            font-size: 26px;
            font-weight: 600;
            color: $color-second;
            z-index:2;

        }

        &--img{
            position: absolute;
            width:40%;
        }


    }

    &__content{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:18px;


        &--txt1{
            color: $color-second;
            text-align: center;
            font-size: 15px;
            font-weight: 600;
            color: $color-third;
        }

        &--txt2{
            color: $color-second;
            text-align: justify;
            font-size: 13px;
            // margin: 0 20px;
        }

        &--txt3{
            color: $color-second;
            text-align: center;
            font-size: 15px;
            font-weight: 500;
            // margin: 0 30px;
        }
    }
}