@import url('https://fonts.googleapis.com/css2?family=Mulish&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gruppo&family=Mulish&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:wght@300&display=swap');

*{
    //font-family: "Gruppo", sans-serif;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    color: $color-third;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


body{
    background-color: $color-second;
    box-sizing: content-box;
    max-width: 100vw;
}

li{
    list-style: none;
}

h1{
    text-align: center;
    font-size: 50px;
    font-family: "Gruppo", sans-serif;
    color: $color-first;
    margin:10px 0 20px 0;

    @media (max-width: $bp-t) {
        font-size: 40px;
    }
}

h2{
    text-align: center;
    font-size: 40px;
    font-family: "Gruppo", sans-serif;
    font-weight: 500;
    color: $color-fourth;

    @media (max-width: $bp-m) {
        font-size: 30px;
    }
}

h3{
    font-family: "Gruppo", sans-serif;
    font-size: 40px;
    font-weight: 500;
}

h4{
    font-family: "Gruppo", sans-serif;
    font-size: 30px;
    font-weight: 500;
}

.link{
    font-size: 20px;
    
    &:hover{
    color: $color-fith;
    }
}