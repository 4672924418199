@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');



.formulacard{
    width: 350px;
    height: 750px;
    border-radius: 75px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding:20px;
    gap:15px;
    background: radial-gradient(circle, rgba(157,151,207,1) 30%, 	rgb(128, 115, 191) 100%);

    &__special{
        width:400px;
        height: 800px;
        background: radial-gradient(circle, rgb(138, 201, 184) 30%, rgb(72, 157, 135) 100%);
        box-shadow: 1.2rem 1.2rem 0.3rem rgba(29, 30, 28, 0.26);

        h3{
            font-size: 50px;
        }
        h4{
            font-size: 38px;
        }
        .formulacard__header--price{
            font-size: 45px;
        }
        .formulacard__content--txt1{
            font-size:20px;
        }
        .formulacard__content--txt2{
            font-size:16px;
        }
    }

    @media (max-width: $bp-t) {
        width:100%;
        max-width: 400px;
        min-width: 300px;
    }

    @media (max-width: $bp-m) {
        height: auto;
    }


    &__title{
        text-align: center;
        font-size: 35px;
        font-weight: 400;
        color: $color-second;
    }

    &__stitle{
        text-align:center;
    }

    &__price{
        font-family: "Raleway", sans-serif;
        font-size: 35px;
        font-weight: 500;
    }


    &__content{

        &--txt1{
            color: $color-second;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            color: $color-third;
        }

        &--txt2{
            color: $color-second;
            text-align: justify;
            font-size: 14px;
        }
    }

}