.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-third;
    padding: 50px 0;

    &__txt{
        color: white;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        font-size: 14px;

        &--button{
            cursor:pointer;

            &:hover{
                color: pink;
            }
        }
    }
}