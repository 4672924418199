.formules{
    @include container;
    align-items:normal ;
    gap: 20px;

    &__content{

    
        &--center{
            text-align: center;
            font-size: 20px;
            color:$color-third;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            padding: 20px 0;

            &--color{
                color:$color-fourth;
            }
        }

        &--point{
            margin-left:40px;
            line-height: 2em;
        
            @media (max-width: $bp-m) {
                margin-left: 5px;
            }

        }

        &--choice{
            gap:50px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: $bp-t) {
                flex-direction: column;
            }
        }
    }
}

.deroulement{
    @include container-large;
    padding: 40px;
    background-color: $color-third;

    @media (min-width: $bp-ld) {
        padding: 20px 250px;
    }

    @media (max-width: $bp-t) {
        padding: 20px;
    }

    h3,p,strong{
        color: $color-second;
    }

    &__intro{
        color: $color-second;
        text-align: center;
        padding:0 100px;

        @media (max-width: $bp-t) {
            padding: 0;
        }
    }

    &__title{
        color:$color-second;
    }

    &__etape{
        display: flex;
        flex-direction: column;
        align-items: center;

        
        &__part1{
            display: flex;
            align-items: center;
            gap:100px;

            @media (max-width: $bp-m) {
                flex-direction: column;
                gap:10px;
            }

            &--img{
                width:30%;

                @media (max-width: $bp-m) {
                    width:80%;
                }
            }

            &--content{
                display: flex;
                flex-direction: column;
                gap:50px;

                h3{
                    color: $color-first;

                    @media (max-width: $bp-t) {
                        font-size: 30px;
                        text-align: center;
                    }
                }


                p{
                    font-size: 16px;
                    text-align: justify;
                }
            }
        }

        &__part2{
            padding:0 200px 0 100px;
            text-align: justify;

            @media (max-width: $bp-t) {
                padding: 20px 100px 20px 50px;
            }

            @media (max-width: $bp-m) {
                padding: 20px 10px;
                font-size: 15px;
                text-align: left;
            }
        }


    }
}

.reverse{
    flex-direction: row-reverse;

    @media (max-width: $bp-m) {
        flex-direction: column;
    }
}

.details{
    @include container;



    &__formule{
        display: flex;
        flex-direction: column;
        gap:40px;
        margin-bottom: 50px;
        padding-bottom: 50px;
        border-bottom: 1px solid $color-third;


        &--title{
            font-size: 25px;
            text-align: center;
        }

        &--content{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 30px;


            @media (max-width: $bp-t) {
            display: grid;
            grid-template-columns: repeat(2, 1fr)
            }

            @media (max-width: $bp-m) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
}
}